import Model from '@itsfadnis/jsonapi-client'

class NavigationLink extends Model {
  static baseURL = '/navigation_links';

  static _type = 'navigation-links';

  constructor (args = {}) {
    super(args)

    Model.call(this, args)

    this.title = args.title
    this.linkableId = args.linkableId
    this.linkableType = args.linkableType
    this.linkableTitle = args.linkableTitle
    this.linkableUrl = args.linkableUrl
    this.region = args.region
    this.sequence = args.sequence
    this.parentId = args.parentId
    this.createdAt = args.createdAt
  }

  // body: [1, 2, 3, 4, 5]
  sort (body) {
    return this.constructor.adapter
      .post(`${this.constructBaseURL()}/sort`, body)
  }
}

export default NavigationLink
