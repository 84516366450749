import Model from '@itsfadnis/jsonapi-client'

class RecommendedProjects extends Model {
  static _type = 'recommendations';

  static baseURL = '/recommendations';

  constructor (args = {}) {
    super(args)

    Model.call(this, args)

    this.name = args.name
    this.description = args.description
    this.imageUrl = args.imageUrl
    this.viewCount = args.viewCount
    this.subscribersCount = args.subscribersCount
    this.permalink = args.permalink
  }
}

export default RecommendedProjects
