/* global FormData, location */

import TokenStore from './token-store';

export default class FileUpload {
  static async perform(file) {
    if (!file) {
      throw new Error('Missing required argument: file');
    }

    const fd = new FormData();
    fd.append('data[attributes][file]', file);

    const response = await fetch('/api/v2/file_uploads', {
      method: 'POST',
      body: fd,
      headers: {
        'X-API-Token': `Bearer ${TokenStore.apiToken}`,
      },
    });

    if (response.ok) {
      const json = await response.json();
      const url = json.data.attributes.url;

      if (process.env.NODE_ENV === 'production') {
        return url;
      }

      return location.origin + url;
    }

    throw response;
  }
}
