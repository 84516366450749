import Model from '@itsfadnis/jsonapi-client'

class ParticipantSegment extends Model {
  static baseURL = '/participant_segments';

  static _type = 'participant-segments';

  constructor (args = {}) {
    super(args)

    Model.call(this, args)

    this.name = args.name
    this.createdAt = args.createdAt
    this.updatedAt = args.updatedAt
  }
}

export default ParticipantSegment
