import Model from '@itsfadnis/jsonapi-client'
import mixin from './internal/mixin'
import stateMachine from './internal/state-machine'
import ParticipantSegment from './participant-segment'
import isBlank from './internal/is-blank'
import Errors from '@itsfadnis/jsonapi-client/dist/jsonapi-error'

class Project extends Model {
  static _type = 'projects';

  static baseURL = '/projects';

  constructor (args = {}) {
    super(args)

    Model.call(this, args)

    this.name = args.name || ''
    this.description = args.description
    this.permalink = args.permalink
    this.surveyCount = args.surveyCount
    this.publishedAt = args.publishedAt
    this.createdAt = args.createdAt
    this.visibilityMode = args.visibilityMode
    this.state = args.state
    this.descriptionDisplayMode = args.descriptionDisplayMode || 'detailed'
    this.metaKeywords = args.metaKeywords
    this.metaDescription = args.metaDescription
    this.bannerUrl = args.bannerUrl
    this.bannerCaption = args.bannerCaption || ''
    this.imageUrl = args.imageUrl
    this.imageCaption = args.imageCaption
    this.imageDescription = args.imageDescription
    this.parentId = args.parentId
    this.textWrapMode = args.textWrapMode || 'textwrap_default'
    this.archivalReasonMessage = args.archivalReasonMessage
    this.restrictForumCreation = args.restrictForumCreation
    this.redirectUrl = args.redirectUrl
    this.platformAnalyticsTagList = args.platformAnalyticsTagList || []
    this.widgetResourceCount = args.widgetResourceCount
    this.quickPollLayout = args.quickPollLayout || 'tool'
    this.segments = this.hasMany(ParticipantSegment, args.segments)
    this.contributionCount = args.contributionCount
    this.surveyWithFileUploadQuestionCount = args.surveyWithFileUploadQuestionCount
    this.placesWithPhotosEnabledCount = args.placesWithPhotosEnabledCount
    this.isSocialSharingModalEnabled = args.isSocialSharingModalEnabled
    this.password = args.password
    this.roleInCurrentContext = args.roleInCurrentContext
    this.access = args.access
  }

  static MAX_LENGTH = 255;

  static clone (id) {
    const self = this
    return this.adapter
      .post(this.constructBaseURL() + '/' + id + '/clone')
      .then(function (response) {
        return self.deserialize(response.data)
      }).catch(function (err) {
        self.errors = new Errors(err.data)
        throw err
      })
  }

  validate () {
    if (isBlank(this.name)) {
      this.errors.add({
        code: 'blank',
        source: {
          pointer: '/data/attributes/name'
        }
      })
    } else {
      const { MAX_LENGTH } = this.constructor
      if (this.name.length > MAX_LENGTH) {
        this.errors.add({
          code: 'too_long',
          meta: {
            count: MAX_LENGTH
          },
          source: {
            pointer: '/data/attributes/name'
          }
        })
      }
    }

    if (this.bannerCaption.length > this.constructor.MAX_LENGTH) {
      this.errors.add({
        code: 'too_long',
        meta: {
          count: this.constructor.MAX_LENGTH
        },
        source: {
          pointer: '/data/attributes/banner-caption'
        }
      })
    }

    if (isBlank(this.permalink)) {
      this.errors.add({
        code: 'blank',
        source: {
          pointer: '/data/attributes/permalink'
        }
      })
    }

    if (['tool', 'widget'].indexOf(this.quickPollLayout) === -1) {
      this.errors.add({
        code: 'invalid',
        source: {
          pointer: '/data/attributes/quick-poll-layout'
        }
      })
    }
  }

  // Deletes a project
  static delete (projectId) {
    return this.adapter
      .delete(`${this.constructBaseURL()}/${projectId}`)
  }

  associateSegments () {
    return this.constructor.adapter
      .put(`${this.constructBaseURL()}/${this.id}/visibility`, this.serialize())
  }
}

mixin(
  stateMachine({
    method: 'put',
    actions: [
      'publish',
      'unpublish',
      'archive',
      'unarchive',
      'hide',
      'visible'
    ]
  }),
  Project.prototype
)

export default Project
