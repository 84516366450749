import React from 'react'

const withI18 = (App, locales = []) =>
  class extends React.Component {
    static async getInitialProps (ctx) {
      let appProps = {}
      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps(ctx)
      }
      return {
        ...appProps,
        namespacesRequired: locales
      }
    }

    render () {
      return <App {...this.props} />
    }
  }

export default withI18
