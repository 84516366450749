const defaults = {
  method: 'patch',
  actions: ['publish', 'unpublish', 'archive', 'unarchive']
}

const stateMachine = (opts = {}) => {
  const { method, actions } = { ...defaults, ...opts }
  return actions.reduce((machine, action) => {
    machine[action] = function () {
      return this.constructor.adapter[method](
        `${this.constructBaseURL()}/${this.id}/${action}`
      )
    }
    return machine
  }, {})
}

export default stateMachine
